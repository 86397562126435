import * as React from 'react';
import Helmet from 'react-helmet';
import ReactHtmlParser from 'react-html-parser';
import { graphql } from 'gatsby';

import Layout from '../layouts/Layout';
import Link from '../components/Link';
import FancyPageHeader from '../components/shared/FancyPageHeader';
import BlogSidebar from '../components/blog/BlogSidebar';
import MarkdownAnchorIcon from '../components/markdown/MarkdownAnchorIcon';
import MarkdownImage from '../components/markdown/MarkdownImage';

import { IBlogpost } from '../interfaces/Markdown';
import { getFeaturedImage } from '../utils/markdown';
import MarkdownLink from '../components/markdown/MarkdownLink';

interface Props {
  data: {
    markdownRemark: IBlogpost;
  };
}

// Style inspired by https://preview.webpixels.io/boomerang-v3.6.1/pages/blog/blog-post-sidebar.html
const Blogpost: React.SFC<Props> = ({ data: { markdownRemark: post } }) => (
  <Layout>
    <Helmet>
      <title>{`${post.frontmatter.title} · Blog`}</title>
      <meta name='description' content={post.excerpt} />
      <meta property='og:title' content={post.frontmatter.title} />
      <meta property='og:description' content={post.excerpt} />
      <meta property='og:type' content='article' />
      <meta property='og:site_name' content='VersionPress' />
      <meta property='og:image' content={getFeaturedImage(post)} />
      <meta name='twitter:card' content='summary' />
      <meta name='twitter:site' content='@versionpress' />
    </Helmet>
    <FancyPageHeader
      title='Blog'
      titleLink='/blog/'
      subtitle='News from the versioned universe'
      className='blog-header'
    />
    <div className='blog slice container'>
      <div className='row'>
        <div className='col-xl-8'>
          <div className='block block-post'>
            <div className='mb-4'>
              <h1 className='heading heading-2 strong-400 text-normal'>{post.frontmatter.title}</h1>
              <ul className='inline-links inline-links--style-2 mt-1'>
                <li>{post.timeToRead} min read</li>
                <li>{post.frontmatter.date}</li>
                {post.frontmatter.updateDate && <li>updated {post.frontmatter.updateDate}</li>}
                <li>{post.frontmatter.author}</li>
              </ul>
            </div>
            <div className='block-body block-post-body'>
              {ReactHtmlParser(post.html, {
                transform: (node: any, index: number) => {
                  if (node.name === 'a' && node.children[0].name === 'svg') {
                    // Workaround for anchor "viewbox" and "fill-rule" errors
                    // It's a bug in the "react-html-parser" library
                    return <MarkdownAnchorIcon href={node.attribs.href} key={index} />;
                  }

                  if (node.name === 'a' && node.children[0].name !== 'svg') {
                    return <MarkdownLink href={node.attribs.href} node={node} key={index} />;
                  }

                  if (node.name === 'img') {
                    return <MarkdownImage node={node} key={index} />;
                  }
                },
              })}
            </div>
            <div className='slice text-center'>
              <Link to='https://reddit.com/r/versionpress' className='btn btn-styled btn-base-1 btn-circle btn-outline'>
                Comment on Reddit
              </Link>
            </div>
          </div>
        </div>
        <div className='col-xl-3 ml-auto'>
          <BlogSidebar responsiveBreakpoint='xl' hideWhenAtBottom={true} />
        </div>
      </div>
    </div>
  </Layout>
);

export default Blogpost;

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt(pruneLength: 225)
      frontmatter {
        title
        author
        date(formatString: "DD MMM, YYYY")
        updateDate(formatString: "DD MMM, YYYY")
        featuredImage
      }
      timeToRead
    }
  }
`;
